<script setup lang="ts">
   import {
      Listbox,
      ListboxButton,
      ListboxOption,
      ListboxOptions,
   } from '@headlessui/vue';
   import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
   const { updateTimeZone } = useTimeZoneStore();

   const getUTCOffset = (timeZoneValue: string) => {
      const date = new Date();
      const formatOptions: Intl.DateTimeFormatOptions = {
         timeZone: timeZoneValue,
         hour12: false,
         hour: '2-digit',
         minute: '2-digit',
      };
      const formatter = new Intl.DateTimeFormat('en-US', formatOptions);
      const parts = formatter.formatToParts(date);

      const hourPart = parts.find((part) => part.type === 'hour');
      const minutePart = parts.find((part) => part.type === 'minute');
      if (!hourPart || !minutePart) return '';
      const hour = parseInt(hourPart.value, 10);
      const minute = parseInt(minutePart.value, 10);

      const offset = -(
         date.getUTCHours() -
         hour +
         (date.getUTCMinutes() - minute) / 60
      );
      const sign = offset >= 0 ? '+' : '-';
      const absoluteOffset = Math.abs(offset);
      const offsetHours = Math.floor(absoluteOffset);
      const offsetMinutes = (absoluteOffset % 1) * 60;

      return `GMT${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
   };

   const getTimezonesGroupedByRegionWithOffset = () => {
      const timezonesList = Intl.supportedValuesOf('timeZone');
      const regions: {
         [key: string]: Timezone[];
      } = {};

      timezonesList.forEach((timezone) => {
         const [region, city] = timezone.split('/');
         if (!regions[region]) {
            regions[region] = [];
         }

         const offset = getUTCOffset(timezone);
         const hourOffset = parseInt(
            offset.split(':')[0].replace('GMT', ''),
            10
         );
         const minuteOffset = parseInt(offset.split(':')[1], 10);
         regions[region].push({
            value: timezone,
            name: `${city ? city.replace('_', ' ') : timezone} (${offset})`,
            shortValue: offset,
            offset: hourOffset + minuteOffset / 60,
         });
      });
      // sort by offset
      Object.keys(regions).forEach((region) => {
         regions[region].sort((a, b) => a.offset - b.offset);
      });
      return regions;
   };

   const groupedTimezonesWithOffset = computed(() =>
      getTimezonesGroupedByRegionWithOffset()
   );

   interface Timezone {
      name: string;
      value: string;
      shortValue: string;
      offset: number;
   }

   interface TimezoneCategory {
      name: string;
      timezones: Timezone[];
   }
   const timezones: ComputedRef<TimezoneCategory[]> = computed(() =>
      Object.keys(groupedTimezonesWithOffset.value).map((region) => ({
         name: region,
         timezones: groupedTimezonesWithOffset.value[region],
      }))
   );

   const selectedTimezone = ref<Timezone>();
   const search = ref('');
   const { currentClientTimezone } = storeToRefs(useTimeZoneStore());

   const filteredTimezones = computed(() => {
      if (!search.value) return timezones.value;

      return timezones.value
         .map((category) => ({
            ...category,
            timezones: category.timezones.filter((tz) =>
               tz.name.toLowerCase().includes(search.value.toLowerCase())
            ),
         }))
         .filter((category) => category.timezones.length > 0);
   });
   const getDefaultTimezone = () => {
      let timezoneList = timezones.value.flatMap(
         (timezone) => timezone.timezones
      );
      selectedTimezone.value = timezoneList.find(
         (timezone) => timezone.value === currentClientTimezone.value
      );
   };
   watch(
      () => currentClientTimezone.value,
      () => {
         getDefaultTimezone();
      },
      { immediate: true }
   );
   const props = defineProps<{
      onlyView?: boolean;
      extraClass?: string;
   }>();
   const emit = defineEmits<{
      (event: 'select', value: string): void;
   }>();

   const changeTimezone = async (timezone: string) => {
      if (props.onlyView) {
         return emit('select', timezone);
      }
      await updateTimeZone(timezone);
   };
</script>
<template>
   <div class="relative">
      <Listbox v-model="selectedTimezone">
         <div class="relative mt-1">
            <ListboxButton
               class="relative flex w-full cursor-default rounded-full bg-primary-50 py-2 px-2 xl:px-3 text-xs text-primary-500"
            >
               <span class="hidden lg:block">
                  {{ selectedTimezone?.name }}
               </span>
               <span class="lg:hidden">
                  {{ selectedTimezone?.shortValue }}
               </span>
               <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
               >
                  <ChevronUpDownIcon
                     class="h-5 w-5 text-gray-400"
                     aria-hidden="true"
                  />
               </span>
            </ListboxButton>

            <transition
               leave-active-class="transition duration-100 ease-in"
               leave-from-class="opacity-100"
               leave-to-class="opacity-0"
            >
               <ListboxOptions
                  class="absolute mt-1 max-h-96 sm:min-w-96 w-fit overflow-auto rounded-md bg-white pb-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
               >
                  <div class="sticky top-0 px-3 z-20 bg-white py-4">
                     <input
                        type="text"
                        class="w-full border border-gray-700 rounded-md py-3 px-4 text-gray-900 focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Search"
                        v-model="search"
                     />
                  </div>
                  <div
                     v-for="category in filteredTimezones"
                     :key="category.name"
                     class="mx-3 shadow-sm p-2 overflow-auto border custom-border"
                  >
                     <div class="px-3 py-3 text-base bg-primary-50">
                        {{ category.name }}
                     </div>
                     <ListboxOption
                        v-for="timezone in category.timezones"
                        :key="timezone.value"
                        :value="timezone"
                        class="py-2"
                        v-slot="{ active, selected }"
                        @click.prevent="changeTimezone(timezone.value)"
                     >
                        <li
                           :class="[
                              active ? ' text-primary-500' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-10 pr-4',
                           ]"
                        >
                           <span
                              :class="[
                                 selected ? 'font-medium' : 'font-normal',
                                 'block truncate',
                              ]"
                           >
                              {{ timezone.name }}
                           </span>
                           <span
                              v-if="selected"
                              :class="[
                                 active ? 'text-primary-500' : (
                                    'text-primary-500'
                                 ),
                                 'absolute inset-y-0 left-0 flex items-center pl-3',
                              ]"
                           >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                           </span>
                        </li>
                     </ListboxOption>
                  </div>
               </ListboxOptions>
            </transition>
         </div>
      </Listbox>
   </div>
</template>
